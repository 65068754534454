/**
 * Feature attribution enum for tracking feature usage/attribution
 *
 * @note All feature codes are intended to be 8 characters or less in length
 *
 * @see https://hello.atlassian.net/wiki/x/lQxt9Q
 */
export enum FeatureCode {
	/** Confluence Home Feeds (Following and Popular) **/
	HOME_FEED = 'HOMEFEED',
	/** Confluence End of Page Recommendations (Related Pages) **/
	EOP_REC = 'EOPREC',
	/** Confluence End of Page Recommendations (Related Pages) Hover Card **/
	EOP_REC_CARD = 'EOPCARD',
	/** Confluence Page Tree **/
	PAGE_TREE = 'PAGETREE',
	/** Confluence Page Tree Card **/
	PAGE_TREE_CARD = 'TREECARD',
	/** Following Feed email **/
	FOLLOWING_FEED_EMAIL = 'FLLWEML',
	/** Popular Feed email **/
	POPULAR_FEED_EMAIL = 'PPLREML',
	ADMIN_KEY_EMAIL = 'ADMINEML',
	ATTACHMENT_EMAILS = 'ATTCHEML',
	BATCH_EMAIL = 'BATCHEML',
	BLOG_CREATED_EMAIL = 'BLOGEML',
	BULK_ARCHIVE_EMAIL = 'BLKAREML',
	BULK_OWNER_CHANGE_EMAIL = 'BLKOWEML',
	CONFLUENCE_WRAPPED_EMAIL = 'WRAPEML',
	CONTACT_ADMINISTRATORS_EMAIL = 'CTADMEML',
	CONTENT_OWNER_CHANGE_EMAIL = 'OWNREML',
	CONTENT_REDACTION_EMAIL = 'RDCTDEML',
	EXCO_ADDED_TO_SPACE_EMAIL = 'EXCASEML',
	EXCO_REQUEST_ACCESS_EMAIL = 'EXCRAEML',
	EXCO_SPACE_READY_EMAIL = 'EXCSREML',
	IMPORTER_NOTIFICATIONS = 'IMPRTNTF',
	LIVE_PAGE_PUBLISHED_EMAIL = 'LPPEML',
	MENTIONS_EMAIL = 'MNTNEML',
	PAGE_CREATED_EMAIL = 'PAGEEML',
	REMINDER_EMAIL = 'RMNDREML',
	REQUEST_AND_GRANT_ACCESS_EMAILS = 'REQACEML',
	REQUEST_AND_GRANT_ACCESS_SLACK = 'REQACSLK',
	TASKS_EMAIL = 'TASKEML',
	TEAM_CALENDAR_EMAIL = 'CLNDREML',
	WHITEBOARD_CREATED_EMAIL = 'WTBRDEML',
	CONTENT_REDACTION_IN_APP = 'RDCTDAPP',
	DAILY_DIGEST_EMAIL = 'DIGSTEML',
	MENTIONS_REMINDER_EMAIL = 'MNTNREML',
	PUBLIC_LINK_EMAIL = 'PBLNKEML',
	REQUEST_AND_GRANT_ACCESS_IN_APP = 'REQACAPP',
}

export type GetFeatureCodesResponse = { from: FeatureCode | undefined };

export const FEATURE_CODES_QUERY_PARAM = 'atl_f';
export const CONFLUENCE_INSIGHTS_FEATURE_CODES_FEATURE_GATE =
	'confluence_frontend_insights_feature_attribution';
